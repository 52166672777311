




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { AdminLoginUrl, AutoOpenAdmin } from '../../sdk/common/SysConst';
@Component
export default class InsAdmin extends Vue {
  private opened: Boolean = false;
  loginAdmin () {
    window.open(AdminLoginUrl, '_blank');
  }
  mounted () {
    console.log('admin mounted');
    if (AutoOpenAdmin) {
      // window.open(AdminLoginUrl, '_blank');
      window.location.href = AdminLoginUrl;
      this.opened = true;
    } else {
    }
  }
}
